import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const Directories = (props) => {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div>
      <div className="container">
        <div className="row justify-content-center pd-top-100">
          <div className="col-lg-5 col-md-6 text-center content-directorios">
            <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/Maldonado%20Construction%2C%20Repair%20%26%20Remodeling%20LLC.jpg?alt=media&token=3a1f3cb6-f929-419a-a83e-472d5f79efd0"/>
          </div>
        </div>

        <h2 className=" text-center">Find Us</h2>
        <div className="row justify-content-center pd-top-100 ">
          <div className="col-lg-5 col-md-6 text-center content-directorios">
            {
              rpdata?.gmb?.link.length > 1 ?
                <a href={rpdata?.gmb?.link} target="_blank" rel="noopener">
                  <img src={rpdata?.gmb?.img} alt="Not found" width={'250px'} />
                </a>
                : null
            }
          </div>
        </div>
        <div className="row justify-content-center pd-top-20">
          {rpdata?.dbSocialMedia?.directorios.map((item, index) => {
            return (
              <div
                className="col-lg-3 col-md-6 text-center content-directorios"
                key={index}
              >
                <a href={item.url} target="_blank">
                  <img src={item.image}></img>
                </a>
              </div>
            );
          })}
          {rpdata?.dbSocialMedia?.redes.map((item, index) => {
            return (
              <div
                className="col-lg-3 col-md-6 text-center content-directorios"
                key={index}
              >
                <a href={item.url} target="_blank">
                  <img src={item.image}></img>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Directories;